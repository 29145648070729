import PropTypes from "prop-types"
import { useEffect, useState, Fragment } from "react"
import { useRouter } from "next/router"
import Link from "next/link"
import dynamic from "next/dynamic"
import classNames from "classnames"
import { ReactSVG } from "react-svg"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion"

const Select = dynamic(() => import("react-select"))

// ---------------------------------------------------------

import RenderBlocks from "@lib/renderBlocks"
import { parameterize, intersection } from "@lib/utils"

// ---------------------------------------------------------

import Layout from "@layout/index"
import Button from "@components/button"
import Card from "@components/card"
import RecommendedReading from "@components/recommended-reading"

const IntegrationCard = dynamic(() => import("@components/card-rounded/card-integration"), {
  ssr: false
})

// ---------------------------------------------------------

import {
  filter_grid_container,
  accordion_item,
  accordion_item_button,
  accordion_item_panel,
  button_clear_filters,
  close_icon,
  card_items,
  filter_container,
  filters_container,
  filter_container_smaller_screens,
  filters_header,
  filter_item,
  filter_item_is_active,
  filter_select,
  load_more_button,
  no_results_message,
  search_input,
  header
} from "./styles.module.scss"

// ---------------------------------------------------------

const LIMIT = 12

// ---------------------------------------------------------
// TODO: Replace react-accessible-accordion with custom component

const FilterGrid = (props) => {
  const {
    preview,
    route,
    pageData,
    contentData,
    recommendedProps,
    title,
    cardType = "default",
    hasTextSearch = false,
    dropdownOptions,
    customNoResultsMessage,
    canonical
  } = props

  const router = useRouter()

  // Create an initial values object with the option groups with no selections.
  // Used to populate the initial props and to reset props on clear filter.
  const initialValues = {}
  for (const key in dropdownOptions) {
    initialValues[key] = new Set()
  }

  const selectionKeys = Object.keys(dropdownOptions)
  const dropdownKeys = selectionKeys.map((el) => {
    return `dropdown${el}`
  })

  const pageModules = pageData?.modulesCollection?.items ?? []
  const { title: pageTitle, excerpt, featuredImage } = pageData

  const [selections, setSelections] = useState(initialValues)
  const [endPost, setEndPost] = useState(LIMIT)
  const [searchValue, setSearchValue] = useState("")

  // add a no index if more than one param has been selected for the page
  const allSelectionsArray = selectionKeys.reduce((acc, curr) => {
    return [...acc, ...selections[curr]]
  }, [])
  const shouldNotIndex = allSelectionsArray.length > 1 || searchValue

  const metaValues = { pageTitle, excerpt, featuredImage: featuredImage || undefined }
  const customMeta = {}

  if (shouldNotIndex) {
    customMeta["robots"] = "noindex"
  }

  // Resets filters to the initialValue and resets the EndPost to the original pagelimit
  const resetFilters = () => {
    setSelections(initialValues)
    setSearchValue("")
    setEndPost(LIMIT)
  }

  // Show the next set of resources appended to the existing set (LIMIT items)
  const loadMore = () => {
    setEndPost(endPost + LIMIT)
  }

  /**
   * Whenever the query string changes, update local state
   */
  useEffect(() => {
    if (!router.isReady) return
    const queryParams = router.query
    const querySelections = {}

    for (const key in dropdownOptions) {
      querySelections[key] = new Set(
        // Populate the selections object based on the given query string
        // The ternary coerces to an array if a string (single value) is given
        // Filter removes any falsy values from the array
        Array.isArray(queryParams[key]) ? queryParams[key] : [queryParams[key]].filter((idx) => idx)
      )
    }
    if (router.query["search"]) {
      setSearchValue(router.query["search"])
    }
    setSelections(querySelections)
  }, [router, dropdownOptions])

  /**
   * Builds the query object that will be passed to <Link /> for each filter
   * @param {String} group - E.g. "category" or "topic"
   * @param {String} filter - Filter value, e.g. "Customer Data Platform"
   * @returns {Object} Object with new query values
   */
  const buildQuery = (group, filter) => {
    const value = router.query[group]
    const els = new Set(Array.isArray(value) ? value : [value])

    if (els.has(filter)) {
      els.delete(filter)
    } else {
      els.add(filter)
    }

    els.delete(undefined) // Purge undefined... just in case.
    let params = {
      ...router.query,
      [group]: Array.from(els)
    }
    return params
  }

  /**
   * Match posts against selected filters
   */
  const filteredContent = (() => {
    const selectionArrays = {}

    selectionKeys.forEach((key) => {
      selectionArrays[key] = [...selections[key]]
    })

    return contentData.filter((item) => {
      /**
       * Return only objects where the selectedValues are matched by the values with the same
       * key on the item we are checking for
       */
      const filterMatch = selectionKeys.every((key) => {
        // When using tags, the keys + values are not a one:one pairing. The following ensures
        // that we are checking the tags for industry values - and append industry to the text
        // to make sure we are filtering the right data.
        let transformedKey = key
        let transformedSelections
        if (key === "industries") {
          transformedKey = "tags"
          transformedSelections = selectionArrays[key].map((el) => "Industry: " + el)
        }

        return (
          selectionArrays[key].length < 1 ||
          intersection(transformedSelections || selectionArrays[key], item[transformedKey]).length >
            0
        )
      })

      // if selected, filters out only resources that have the search input in the title
      const titleMatch = item.title?.toLowerCase().includes(searchValue.toLowerCase())

      return titleMatch && filterMatch
    })
  })()

  const contentToDisplay = filteredContent.slice(0, endPost)

  /**
   * When filters change this updates the selections state value. It toggles the
   * value from the specific group i.e. selections['topic'] based on whether it
   * had already been selected or not
   * @param {String} group - E.g. "category" or "topic"
   * @param {String} filter - Filter value, e.g. "Customer Data Platform"
   */
  const handleFilterChange = (group, filter) => {
    // eslint-disable-next-line
    dataLayer.push({
      event: "filter_item",
      filterGroup: group,
      filterItem: filter
    })

    const selectedItems = selections
    if (selections[group].has(filter)) {
      selectedItems[group].delete(filter)
    } else {
      selectedItems[group].add(filter)
      window._paq
        ? window._paq.push([
            "trackEvent",
            "Filter items",
            `Current Page: ${router.asPath}`,
            `${group} / ${filter}`
          ])
        : null
    }

    setSelections(selectedItems)
    setEndPost(LIMIT)
  }

  const handleMobileFilterChange = (event, group, set) => {
    const { action, removedValue, option } = event
    const value = action === "remove-value" ? removedValue?.value : option?.value
    // Update filter selections
    handleFilterChange(group, value)
    // Update the query string
    router.push(
      {
        pathname: route,
        query: {
          ...router.query,
          [group]: Array.from(set)
        }
      },
      false,
      { shallow: true, scroll: false }
    )
  }

  const renderMobileFilter = (group, arr) => {
    const options = arr.map((v) => ({ value: v, label: v }))
    const set = selections[group]

    const groupId = `${group}-select`

    const selected = Array.from(set).map((v) => ({ value: v, label: v }))

    return (
      <Select
        className={filter_select}
        options={options}
        value={selected}
        id={groupId}
        instanceId={groupId}
        isMulti={true}
        isClearable={false}
        onChange={(_data, event) => handleMobileFilterChange(event, group, set)}
      />
    )
  }

  /**
   * Returns results of search and updates router.
   * @param {*} searchTerm The keyword that is being queried in search.
   */
  const handleSearch = (searchTerm) => {
    setSearchValue(searchTerm)
    router.push(
      {
        pathname: route,
        query: {
          ...router.query,
          search: searchTerm
        }
      },
      false,
      { shallow: true, scroll: false }
    )
  }

  const handleLeaveFocus = (searchTerm) => {
    // eslint-disable-next-line
    dataLayer.push({
      event: "search_item_grid",
      searchTerm: searchTerm
    })
  }

  /**
   * Render desktop and mobile controls for Filter
   * @param {String} title
   * @param {Array} arr
   * @returns
   */
  const renderFilter = (title, arr) => {
    const group = parameterize(title)
    const groupKey = `filter-group-${group}`

    const buildItem = (type) => {
      const filterIsActive = selections[group] && Array.from(selections[group]).includes(type)

      return (
        <li
          key={`${groupKey}-${parameterize(type)}`}
          className={classNames({
            [filter_item]: true,
            [filter_item_is_active]: filterIsActive
          })}
        >
          {filterIsActive && <ReactSVG src={`/icons/check-mark.svg`} />}
          <Link
            href={{
              pathname: route,
              query: buildQuery(group, type)
            }}
            shallow
            replace
            onClick={() => handleFilterChange(group, type)}
          >
            {type}
          </Link>
        </li>
      )
    }

    return (
      <Fragment key={groupKey}>
        <AccordionItemHeading>
          <AccordionItemButton className={accordion_item_button}>
            <span>{title}</span>
            <ReactSVG src={`/icons/angle-right.svg`} />
          </AccordionItemButton>
        </AccordionItemHeading>

        <AccordionItemPanel className={accordion_item_panel}>
          <div className={filter_container_smaller_screens}>{renderMobileFilter(group, arr)}</div>
          <ul className={filter_container}>{arr.map((type) => buildItem(type))}</ul>
        </AccordionItemPanel>
      </Fragment>
    )
  }

  let filters = (
    <>
      <div className={filters_header}>
        <span>Filters</span>
        {filteredContent.length < contentData.length && (
          <Link
            className={button_clear_filters}
            href={route}
            onClick={resetFilters}
            replace
            shallow
          >
            <>
              Clear filters
              <span className={close_icon}>
                <ReactSVG src={`/icons/x-mark.svg`} />
              </span>
            </>
          </Link>
        )}
      </div>
      {/* Search bar */}
      {hasTextSearch && (
        <div className={search_input}>
          <input
            type="text"
            name="text-search"
            id="text-search"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
            onBlur={(e) => handleLeaveFocus(e.target.value)}
            placeholder="Search by Title"
          />
          <Button theme="dark">
            <span>
              <ReactSVG src={`/icons/magnifying-glass.svg`} />
            </span>
          </Button>
        </div>
      )}

      {/* Dropdown Filters */}
      <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={dropdownKeys}>
        {selectionKeys.map((el) => {
          return (
            <AccordionItem className={accordion_item} key={`dropdown${el}`} uuid={`dropdown${el}`}>
              {renderFilter(el, dropdownOptions[el])}
            </AccordionItem>
          )
        })}
      </Accordion>
    </>
  )

  const defaultNoResultsMessage = (
    <p>
      No results matched the selected filters.
      <Link className={button_clear_filters} href={route} onClick={resetFilters} replace shallow>
        Clear filters
      </Link>
    </p>
  )

  return (
    <Layout {...metaValues} customMeta={customMeta} preview={preview} canonical={canonical}>
      {pageModules && RenderBlocks(pageModules)}

      {title ? <h1 className={header}>{title}</h1> : null}

      <div className={filter_grid_container}>
        {/* Filters Section */}
        <div className={filters_container}>{filters}</div>

        <div className={card_items}>
          <div data-cy="filter-cards-container">
            {contentToDisplay?.length > 0 &&
              cardType === "default" &&
              contentToDisplay.map((item) => {
                const isWebinar = item.types === "Webinar"
                const isDemo = item.types === "Demo"
                return (
                  <div data-cy="filter-card" key={item.slug}>
                    <Card
                      theme="hover-grid"
                      label={item.type || item.types || "blog"}
                      title={item.title}
                      author={item.authors?.join(", ")}
                      image={item.featuredImage}
                      startDate={item.startDate || item.date}
                      description={item.excerpt}
                      buttonUrl={
                        isWebinar
                          ? `/${route}/webinars/${encodeURIComponent(item.slug)}`
                          : isDemo
                          ? `/${route}/demo/${encodeURIComponent(item.slug)}`
                          : `/${route}/${encodeURIComponent(item.slug)}`
                      }
                      tagCollection={item.tags}
                      route={route}
                    />
                  </div>
                )
              })}
            {contentToDisplay?.length > 0 &&
              cardType === "integration" &&
              contentToDisplay.map((integration) => {
                return (
                  <div data-cy="integration-card" key={integration.title}>
                    <IntegrationCard
                      category={integration.categories}
                      title={integration.title}
                      sourceUrl={integration.sourceUrl}
                      destinationUrl={integration.destinationUrl}
                      description={integration.description}
                      image={{
                        alt: integration.image.description,
                        src: integration.image.url,
                        height: integration.image.height,
                        width: integration.image.width
                      }}
                    />
                  </div>
                )
              })}
          </div>

          {contentToDisplay.length < 1 && (
            <div className={no_results_message}>
              {customNoResultsMessage || defaultNoResultsMessage}
            </div>
          )}

          {endPost < filteredContent.length && (
            <div className={load_more_button}>
              <Button theme="dark" onClick={loadMore}>
                Show more
              </Button>
            </div>
          )}
        </div>
      </div>

      {recommendedProps?.recommended && <RecommendedReading {...recommendedProps} />}
    </Layout>
  )
}

FilterGrid.propTypes = {
  /**
   * CMS Blocks to be shown before the filter + grid content
   */
  pageData: PropTypes.object,

  /**
   * CardType to be shown in the grid
   */
  cardType: PropTypes.oneOf(["default", "integration"]),

  /**
   * Specifies the route that card will link to, if applicable
   */
  route: PropTypes.string,

  /**
   *  React component to override the existing default no results message
   */
  customNoResultsMessage: PropTypes.node,

  /**
   * Specifies the page title to appear at the top of the page
   */
  title: PropTypes.string,

  /**
   * Specifies the content of the filters on the page
   */
  dropdownOptions: PropTypes.object.isRequired,

  /**
   * Specifies whether a text-input will show as one of the filter options
   */
  hasTextSearch: PropTypes.bool,

  /**
   * Populates the recommended section, if recommended content is provided
   */
  recommendedProps: PropTypes.shape({
    recommended: PropTypes.array,
    title: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonUrl: PropTypes.string
  }),

  /**
   * Content Data which populates the cards on the page, after being filtered
   */
  contentData: PropTypes.array,

  /**
   * Specifies a canonical link for SEO
   */
  canonical: PropTypes.string
}

export default FilterGrid
